import React, { useState, useRef, useEffect } from "react";
import { FaRegComments } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import tw from "twin.macro";
import styled from "styled-components";

const IFrameContainer = styled.div`
  ${tw`w-[450px] h-[550px] bg-[#f7f7f7] rounded-md z-10`}

  iframe {
    ${tw`w-[97%] h-[99%]`}
  }
`;

const IframePopup = () => {
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const iframeRef = useRef(null);

  // start
  // useEffect(() => {
  //   const iframe = iframeRef.current;
  //   console.log(iframeRef.current);
  //   const handleFormSubmit = () => {
  //     iframe.style.display = "none";
  //   };

  //   if (iframe) {
  //     const iframeContentWindow = iframe.contentWindow;
  //     iframeContentWindow.addEventListener("load", () => {
  //       const form = iframeContentWindow.document.querySelector("form");

  //       form.addEventListener("submit", handleFormSubmit);
  //     });
  //   }
  // }, []);

  // end

  const handleCloseIconClick = () => {
    setIsIframeVisible(false);
    localStorage.setItem("iframeClosed", "true");
  };

  const handleDocumentClick = (e) => {
    if (
      isIframeVisible &&
      iframeRef.current &&
      !iframeRef.current.contains(e.target)
    ) {
      setIsIframeVisible(false);
      localStorage.setItem("iframeClosed", "true");
    }
  };
  const clearLocalStorageOnUnload = () => {
    localStorage.setItem("iframeClosed", null);
  };

  useEffect(() => {
    const isIframeClosed = localStorage.getItem("iframeClosed") === "true";
    if (isIframeClosed) {
      setIsIframeVisible(false);
      return;
    }

    // const handleScroll = () => {
    //   if (window.scrollY > 200) {
    //     setIsIframeVisible(true);
    //   }

    // };

    const timeout = setTimeout(() => {
      setIsIframeVisible(false);
    }, 5000);

    // window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleDocumentClick);
    window.addEventListener("beforeunload", clearLocalStorageOnUnload);

    return () => {
      // window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleDocumentClick);
      window.removeEventListener("beforeunload", clearLocalStorageOnUnload);
    };
  }, [isIframeVisible]);

  useEffect(() => {
    const handleUnload = () => {
      clearLocalStorageOnUnload();
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);
  return (
    <div>
      {/* Iframe section */}
      {isIframeVisible && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            left: "0",
            top: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          {/* Cross icon to close the iframe */}
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              color: "#e42f17",
              zIndex: 20,
            }}
            onClick={handleCloseIconClick}
          >
            <AiOutlineCloseCircle size={30} />
          </div>
          {/* Your iframe */}
          <IFrameContainer ref={iframeRef} style={{ borderRadius: "8px" }}>
            <iframe
              src="https://2107637b.sibforms.com/serve/MUIFAMAPW-FoW_hNyYUhNMJ6IAJIMkK9IGxQru9mQNyLdU8YRT9xP7gXipzHfZ0r9nJ4VwAk7-BJLEzYT1TS5lv2_KJGMawramzaYdq6EQkmiuFUi3v9oz9sWhtz9ftp5bpbSm-hkVVUVsvNm0B0-4NsislUhHugSZv8_ualRTD9QLWK-ICxklmfquPGCmiNambzN72o3Y-rPyN6"
              frameBorder="0"
              scrolling="auto"
              id="iframe-popup"
              allowFullScreen=""
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
              }}
            ></iframe>
          </IFrameContainer>
        </div>
      )}
    </div>
  );
};

export default IframePopup;
